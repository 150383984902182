<template>
  <div>
    <HomePageTop ref="head"></HomePageTop>
    <loginDialog ref="dialog"/>
    <div class="contentContainer">
      <div style="margin:24px auto 23px;width: 1400px">
        <img alt="" src="../../assets/1688/longPic.png">
      </div>
      <header>
        <div class="leftContainer">
          <div v-for="(item,index) in typeList"
               :key="index"
               class="flexAndCenter"
               @click="updateCollectionId(item)">
            <div :class="from.productCollectionId==item.product_collection_id?'fontWeightBold colorB4272B':''">
              {{ item.name_ja }}
            </div>
            <el-popover placement="bottom" trigger="hover" width="260">
              <div>
                {{ item.tips }}
              </div>
              <div slot="reference" class="questionMark">?</div>
            </el-popover>
          </div>
        </div>
        <div class="rightText">1688 より、情報サポートを受けて提供させて頂いております</div>
      </header>
      <div class="selectContainer">
        <div class="selectLabel">{{ $fanyi('请选择分类查看热搜榜单') }}：</div>
        <el-select v-model="leftValue" size="mini" style="width: 200px;margin-right: 12px" @change="leftOptionChange">
          <el-option
              v-for="item in leftOption"
              :key="item.id"
              :label="item.translate_name"
              :value="item.chinese_name">
          </el-option>
        </el-select>
        <el-select v-model="from.keywords" size="mini" style="width: 200px;margin-right: 12px"
                   @change="list=[];from.page=1;getData()">
          <el-option
              v-for="item in leftChildrenOption"
              :key="item.id"
              :label="item.translate_name"
              :value="item.chinese_name">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" @click="list=[];from.page=1;getData()">確認</el-button>
      </div>
      <!-- 搜索结果块状 -->
      <div v-loading="fullscreenLoading">
        <div class="SearchConterCrosswise">
          <div v-for="(item, index) in list" :key="index"
               :data-aplus-report="item.traceInfo"
               :style="item.hoverStatus === true ? 'border: 1px solid #B4272B;' : ''"
               class="SearchConterCrosswiseCon" data-tracker="offer"
               @mouseleave="goodsMouseleave(index)">
            <!-- 放图片的div -->
            <div class="SearchConterCrosswiseImg" @click="toCommodityDetails(item)"
                 @mouseenter="goodsMouseenter(index)">
              <el-image :src="item.imgUrl" class="biggoodsImg">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <img v-if="index+1<4" :src="require(`../../assets/1688/${index+1}.png`)" alt="" class="topIcon">
              <div v-else class="topIndex">{{ index + 1 }}</div>
            </div>
            <!-- 显示其他信息的div -->
            <div v-show="item.shopInfo==undefined||item.hoverStatus === false"
                 class="rests">
              <p :title="item.titleT" class="goodsTitle">
                <span v-if="item.shopType==1688&&item.isJxhy==false" class="type">1688</span>
                <span v-if="item.shopType==1688&&item.isJxhy==true" class="jxhyType">1688厳選</span>
                {{ item.titleT }}
              </p>
              <p>
              <span>
                {{ Number(item.goodsPrice).toFixed(2) }}{{
                  $fanyi("元")
                }}（{{ (Number(item.goodsPrice) * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}）
              </span>
                <span v-if="item.shopType==1688" style="color: #999999">販売数<span
                    style="color: #B4272D!important;">{{
                    item.monthSold
                  }}</span>件</span>
              </p>
              <div style="display: flex;flex-wrap: wrap">
                <div v-if="item.shopType==1688" style="display: flex;flex-wrap: wrap;margin-bottom: 6px">
                  <div v-for="(newItem,newIndex) in item.sellerIdentities" :key="newIndex">
                    <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 6px':''"
                         class="powerfulMerchantsContainer">
                      <img alt="" src="../../assets/1688/powerfulMerchantsIcon.png">
                      <span>スーパーメーカー</span>
                    </div>
                    <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 6px':''"
                         class="superFactoryContainer">
                      <img alt="" src="../../assets/1688/superFactoryIcon.png">
                      <span>実力商家</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.shopType==1688" class="repurchaseRate">リピート率
                  <span>{{ item.repurchaseRate }}</span>％
                </div>
              </div>
            </div>
            <div v-show="item.shopInfo!=undefined&&item.hoverStatus === true&&item.shopType==1688"
                 class="shopInfo">
              <div v-show="item.hoverStatus === true" :style="item.shopType==1688?'bottom: 38px':'bottom: 0'"
                   class="findSimilarityContainer"
                   @click.stop="imgSearchGoods(item.imgUrl)">{{ $fanyi("查找相似") }}
              </div>
              <div class="shopInfoHeader" style="justify-content: space-between">
                <div class="goodCompare" @click.stop="addCommercialMatch(item)">
                  <img alt="" src="../../assets/1688/goodCompare.png">
                  比較
                </div>
                <div class="goodsFavorite" @click.stop="favoriteGoodsAdd(item)">
                  <img v-if="item.goodsFavoriteStatus" alt="" src="../../assets/SearchResultsPage/Collected.png">
                  <img v-else alt="" src="../../assets/SearchResultsPage/collection.png">
                  {{ item.goodsFavoriteStatus ? $fanyi("取消收藏") : $fanyi("收藏") }}
                </div>
              </div>
              <div
                  :style="item.shopInfo==undefined||$fun.isArray(item.shopInfo)||(!$fun.isArray(item.shopInfo)&&item.shopInfo.shopName==undefined&&item.shopInfo.year==undefined)?'border-bottom:none':''"
                  class="shopDataContainer">
                <div class="newRateContainer">
                  <div style="margin-right: 7px">総合評点</div>
                  <el-rate
                      v-model="item.tradeScore"
                      disabled
                      text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="shopDataBottom">
                  <div class="repurchaseRate" style="margin-right:6px">リピート率
                    <span>{{ item.repurchaseRate }}</span>％
                  </div>
                  <div class="repurchaseRate" style="width: 128px;">販売数<span>{{
                      item.monthSold
                    }}</span>件
                  </div>
                </div>
              </div>
              <div
                  v-if="item.shopInfo!=undefined&&!$fun.isArray(item.shopInfo)&&item.shopType==1688&&item.shopInfo.shopName!=undefined&&item.shopInfo.year!=undefined"
                  class="shopNameContainer">
                <div v-if="item.sellerIdentities!=undefined&&item.sellerIdentities.indexOf('tp_member')==-1"
                     class="yearContainer">
                  <div><img alt="" src="../../assets/1688/shopIcon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div v-if="item.sellerIdentities!=undefined&&item.sellerIdentities.indexOf('tp_member')!=-1"
                     class="tpMemberContainer">
                  <div><img alt="" src="../../assets/1688/tp_member_icon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div :title="item.shopInfo.shopName" class="shopName" @click="shopPageJump(item.shopInfo)">
                  {{
                    item.shopInfo.shopName
                  }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="keywordLoading" class="keywordLoadingContainer">
            <div class="flexAndCenter">
              <img alt="" src="@/assets/loading.gif">
              <div>読み込み中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "@/components/head/newHomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
import loginDialog from "@/components/public/loginDialog.vue";

export default {
  components: {loginDialog, Foot, HomePageTop},
  data() {
    return {
      typeList: [],
      from: {
        keywords: '',
        productCollectionId: '205451985',
        page: 1,
        pageSize: 50
      },
      leftValue: '',
      leftOption: [],
      leftChildrenOption: [],
      list: [],
      fullscreenLoading: false,
      keywordLoading: false,
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    this.$api.rankingList().then((res) => {
      res.data.data.forEach((item) => {
        if (item.status == 1) {
          if (this.typeList.length == 0) {
            this.from.productCollectionId = item.product_collection_id;
          }
          this.typeList.push(item)
        }
      })
    });
    this.$api.getHotSaleCates().then((res) => {
      if (res.code != 0) return this.$message.error(res.msg)
      this.from.keywords = res.data[0].childs[0].chinese_name;
      this.leftValue = res.data[0].chinese_name;
      this.leftOption = res.data;
      this.leftChildrenOption = res.data[0].childs;
      this.getData();
    });
  },
  methods: {
    getData() {
      if (this.fullscreenLoading === false) {
        this.keywordLoading = true;
      }
      let sort = {
        monthSold: 'desc'
      }
      if (this.list.length === 0) {
        this.fullscreenLoading = true;
      }
      this.from.sort = JSON.stringify(sort);
      this.$api.EuropeKeywordSearchProduct(this.from).then((res) => {
        this.fullscreenLoading = false;
        this.keywordLoading = false;
        if (res.code != 0) return this.$message.error(res.msg);
        res.data.result.result.forEach((item) => {
          if (item.whiteImage != undefined && item.whiteImage != '') {
            item.imgUrl = item.whiteImage
          }
          item.hoverStatus = false;
          if (item.tradeScore != undefined) {
            item.tradeScore = Number(item.tradeScore)
          }
          if (item.shopInfo != undefined && !this.$fun.isArray(item.shopInfo) && item.shopInfo.shopTime != undefined) {
            if (item.shopInfo.shopTime.length != 19) {
              item.shopInfo.year = 0
            } else {
              let year = item.shopInfo.shopTime.substr(0, 4);
              var date = new Date();
              item.shopInfo.year = Number(date.getFullYear()) - Number(year);
            }
          }
          this.list.push(item)
        })
        this.favoriteGoodsCheckIn();
      });
    },
    updateCollectionId(val) {
      this.list = [];
      this.from.productCollectionId = val.product_collection_id;
      this.fullscreenLoading = true;
      this.from.page = 1;
      this.getData();
    },
    leftOptionChange(e) {
      for (let i = 0; i < this.leftOption.length; i++) {
        if (e == this.leftOption[i].chinese_name) {
          this.leftChildrenOption = this.leftOption[i].childs;
          this.from.keywords = this.leftOption[i].childs[0].chinese_name;
          this.getData();
        }
      }
    },
    shopPageJump(item) {
      if ((item.shopName != '' && item.shopName != undefined) && ((item.shop_id != '' && item.shop_id != undefined) || (item.shopId != '' && item.shopId != undefined)) && (item.wangwang != '' && item.wangwang != undefined)) {
        this.$fun.routerToPage(`/shopGoods?shopId=${item.shop_id == undefined ? item.shopId : item.shop_id}&wangName=${item.wangwang}&shopName=${item.shopName}&shopType=1688`)
      }
    },
    // 查看店铺是否已收藏
    favoriteGoodsCheckIn() {
      let ids = [];
      this.list.forEach((goodsItem) => {
        goodsItem.goodsFavoriteStatus = false;
        ids.push(goodsItem.goodsId);
      });
      this.$api.favoriteGoodsCheckIn({
        goods_id: ids,
        shop_type: 1688
      })
      .then((res) => {
        if (res.code != 0) return false;
        res.data.forEach((resItem, resIndex) => {
          this.list[resIndex].goodsFavoriteStatus = resItem.inFavorite;
        });
      });
    },
    //追加比较
    addCommercialMatch(val) {
      let commercialMatchList = []
      if (localStorage.getItem("commercialMatchList") != null) {
        commercialMatchList = JSON.parse(localStorage.getItem('commercialMatchList'));
      }
      if (commercialMatchList.length < 30) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('关键词搜索增加比较商品数量统计');
        }
        let flag = commercialMatchList.findIndex(item => item.title == val.titleT)
        if (flag != -1) {
          this.$message.error('該当商品は追加済みです、他の商品をお選び下さい。')
        } else {
          commercialMatchList.push({
            imgUrl: val.imgUrl,
            title: val.titleT,
            titleC: val.titleC,
            price: val.goodsPrice,
            shopType: val.shopType,
            goodsId: val.goodsId,
            monthSold: val.monthSold == undefined ? '' : Number(val.monthSold),
            repurchaseRate: val.repurchaseRate == undefined ? '' : Number(val.repurchaseRate),
            shopName: val.shopInfo == undefined || this.$fun.isArray(val.shopInfo) == true ? '' : val.shopInfo.shopName,
            link: `https://www.rakumart.com/ProductDetails?goods_id=${val.goodsId}&fromPlatform=${val.shopType}`
          })
          localStorage.setItem("commercialMatchList", JSON.stringify(commercialMatchList));
          this.$store.commit('setCommercialMatchGoodsCount', commercialMatchList.length)
          this.$message.success('操作が成功しました')
        }
      } else {
        this.$message.error('最大30個の商品を追加できます')
      }
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      this.$fun.routerToPage("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    //跳转到商品详情
    toCommodityDetails(val) {
      this.$fun.toCommodityDetails(val.goodsId, val.shopType)
    },
    //商品详细鼠标移入
    goodsMouseenter(index) {
      this.list[index].hoverStatus = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(index) {
      this.list[index].hoverStatus = false;
      this.$forceUpdate();
    },
    // 添加收藏商品
    favoriteGoodsAdd(item) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let datas = {
        shop_type: item.shopType,
        goods_id: item.goodsId,
        title: item.titleC,
        image_url: item.imgUrl,
        price: item.goodsPrice,
      };
      if (!item.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$message.success(this.$fanyi(res.msg))
          this.$forceUpdate();
        });
      } else {
        let arr = [];
        arr.push({
          shop_type: item.shopType,
          goods_id: item.goodsId
        })
        this.$api.favoriteGoodsDelete({delete: arr}).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      }
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      let numHeight = scrollTop + clientHeight;
      if (numHeight > scrollHeight - 600 && this.keywordLoading === false) {
        this.keywordLoading = true
        this.fn()
      }
    },
    fn() {
      if (this.keywordLoading === true) {
        this.from.page++;
        this.getData();
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.getbottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getbottom);
  },
}
</script>

<style lang="scss" scoped>
.type {
  width: 46px;
  height: 18px;
  display: inline-block;
  background: #FF4000;
  border-radius: 2px;
  margin-right: 7px;
  text-align: center;
  line-height: 18px;
  font-weight: bold;
  color: #fff;
}

.SearchConterCrosswise {
  width: 1400px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 20px;
  min-height: 500px;
  position: relative;

  > .SearchConterCrosswiseCon:nth-child(5n) {
    margin-right: 0 !important;
  }

  > .SearchConterCrosswiseCon {
    margin-right: 18.5px;
    margin-bottom: 15px;
    position: relative;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 6px;
    height: 430px;

    &:last-child {
      margin-right: 0;
    }

    background-color: white;
    width: 265px;

    > div {
      cursor: pointer;

      &.SearchConterCrosswiseImg {
        width: 261px;
        overflow: hidden;
        height: 261px;
        transition: 0.3s;

        > .goodsImg {
          background-color: transparent;
        }

        > .el-image {
          width: 100%;
          min-height: 100%;
        }

        .topIcon {
          position: absolute;
          right: 0;
          top: 0;
          width: 60px;
          height: 24px;
        }

        .topIndex {
          position: absolute;
          right: 0;
          top: 0;
          width: 24px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          font-size: 14px;
          border-radius: 0 6px 0 6px;
          color: #fff;
          font-weight: bold;
          background: #999;
        }
      }

      &.SearchConterCrosswiseImg:hover {
        > .el-image {
          transform: scale(1.2, 1.2);
        }
      }

      &.rests,
      &.newRests {
        box-sizing: border-box;
        padding: 13px 20px 12px 17px;

        p {
          font-size: 12px;

          font-weight: 400;
          color: #000000;

          &.goodsTitle {
            height: 40px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;

            //p {
            //  width: 46px;
            //  height: 18px;
            //  border-radius: 2px;
            //  background: url("../../assets/1688/goodTag.png") no-repeat;
            //  background-size: 100% 100%;
            //}
          }

          &:nth-child(2) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            > span {
              line-height: 30px;
              font-size: 12px;
              color: #B4272B;
            }
          }
        }
      }

      &.newRests {
        background: #B4272B;

        p {
          color: #fff;

          &:nth-child(2) {
            > span {
              color: #fff !important;
            }
          }
        }
      }

      .realignmentContainer {
        display: flex;
        flex-wrap: wrap;
        margin: 6px 0;

        div {
          height: 24px;
          background: #FFEFEF;
          border-radius: 4px;
          line-height: 12px;
          text-align: center;
          color: #FF4000;
          font-size: 12px;
          padding: 6px;
          box-sizing: border-box;
        }

        div:first-child {
          margin-right: 6px;
        }
      }

      .repurchaseRate {
        width: 100px;
        height: 24px;
        background: #F6F6F6;
        border-radius: 4px;
        line-height: 24px;
        text-align: center;
        color: #333333;
        font-size: 12px;

        span {
          color: #B4272D;
        }
      }
    }
  }
}

.keywordLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1400px;

  img {
    width: 40px;
    margin-right: 20px;
  }

  div {
    font-size: 14px;
  }
}

.contentContainer {
  width: 1400px;
  margin: 0 auto;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 2px solid #B4272B;

    .leftContainer {
      display: flex;
      align-items: center;

      .flexAndCenter {
        color: #333333;
        font-size: 18px;
        margin-right: 45px;
        cursor: pointer;
      }

      .flexAndCenter:last-child {
        margin-right: 0;
      }
    }

    .rightText {
      color: #999999;
      font-size: 14px;
    }
  }

  .selectContainer {
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    padding-left: 19px;
    display: flex;
    align-items: center;
    margin: 20px 0;

    .selectLabel {
      font-size: 14px;
      color: #000000;
    }
  }
}

.powerfulMerchantsContainer {
  background: #F8F6FF !important;
  width: 131px !important;

  span {
    color: #3700E1 !important;
  }
}

.jxhyType {
  padding: 2px 4px 2px 6px;
  background: linear-gradient(90deg, #E4AE42, #C38D22);
  border-radius: 2px;
  font-size: 14px;
  color: #FFFFFF;
}

.superFactoryContainer, .powerfulMerchantsContainer {
  width: 84px;
  height: 24px;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 17px;
    margin-right: 5px;
  }

  span {
    color: #F72A2B;
    font-size: 12px;
  }
}

.shopInfo {
  padding: 0 15px;
  position: absolute;
  left: 0;
  top: 52%;
  background: #fff;
  box-sizing: border-box;
  width: 261px;
  animation: slideUp 1s ease forwards;

  .findSimilarityContainer {
    position: absolute;
    left: 0;
    top: -31px;
    height: 32px;
    width: 261px;
    background: #E0787E;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
  }

  .findSimilarityContainer:hover {
    background: #C40622;
  }

  .shopInfoHeader {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;

    .goodsFavorite, .goodCompare {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      cursor: pointer;
      font-size: 12px;
    }

    .goodCompare {
      border-right: 1px solid #DFDFDF;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }

  .shopDataContainer {
    padding: 20px 0 15px;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    cursor: default;

    .newRateContainer {
      display: flex;
      font-size: 16px;
      color: #999999;
      margin-bottom: 14px;
    }

    .shopDataBottom {
      display: flex;
      align-items: center;
    }
  }

  .shopNameContainer {
    padding: 15px 0;
    display: flex;
    align-items: center;

    .yearContainer, .tpMemberContainer {
      width: 62px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F72A2B;
      display: flex;
      align-items: center;
      margin-right: 5px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:first-child {
        width: 26px;
        height: 24px;
        background: #F72A2B;
        border-radius: 4px 0 0 4px;

        img {
          width: 18px;
          height: 17px;
        }
      }

      div:last-child {
        flex: 1;
        font-size: 12px;
        color: #F72A2B;
      }
    }

    .tpMemberContainer {
      border: 1px solid #FF6000 !important;

      div:first-child {
        background: #FF6000 !important;
      }

      div:last-child {
        color: #FF6000 !important;
      }
    }

    .shopName {
      width: 178px;
      font-size: 14px;
      color: #000000;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/* 定义动画 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>